<template>
    <div class="allcontant">
        <div class="head">
            <div class="headLeft">
                <img style="width:80px;max-height:50px" v-if='!domain' src="https://wlzj-platform.posedu.com.cn/manage/uploads/20210818/1bij1mf9tft4c8dkwmhb928ccb9q909g.png">
                <img style="width:500px;max-height:60px" v-else :src="infoList.doorInfo.logo">
                <div v-if='!domain' style='max-height: 50px;margin-top:26px'>
                    <h1 style="margin-bottom: -5px;margin-top: -30px;letter-spacing: 2px;margin-left: 10px;">位来教育大赛展示平台</h1>
                    <p style="margin-bottom: 0;font-size:11px">Wei Lai Education Competition Display Platform</p>
                </div>
                <div v-else style='max-height: 50px;margin-top:26px'>
                    <h1 class='h1class' style="margin-bottom: -5px;margin-top: -30px;letter-spacing: 2px;margin-left: 10px;" v-html='infoList.doorInfo.name'></h1>
                    <!-- <p  style="margin-bottom: 0;font-size:11px">Wei Lai Education Competition Display Platform</p> -->
                </div>
            </div>
            <div class="headRight" style="position: relative;">
                <router-link :to='{path:"/home"}' style='margin-left:20px'>首页</router-link>
                <router-link :to='{path:"/notice",query:{info:domain}}' style='margin-left:30px'>赛事通知</router-link>
                <!-- <router-link  :to='{path:"/matchNew",query:{info:domain}}'  style='margin-left:30px'>报名通道</router-link> -->
                <a @click="goAnchor('#matchnews')" style='margin-left:30px'>报名通道</a>
                <a href='https://match.posedu.com.cn/mms/#/login' target='_blank' style='margin-left:30px'>省级管理员</a>

            </div>
        </div>
        <el-main>
            <!-- <div class="bread"> <h1>大赛通知</h1>
             <el-breadcrumb separator="/">
       <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
       <el-breadcrumb-item :to="{ path: 'news' }">大赛通知</el-breadcrumb-item>
     </el-breadcrumb></div> -->
            <div class="newbox">
                <div>
                    <div class="title">
                        <h3></h3>
                        <div>
                            <span style="display:inline-block" @click="moren"
                                  :class="{ 'class1' : isActive == 1}">默认</span>
                            <span style="display:inline-block" @click="views"
                                  :class="{'class1' : isActive == 2}">浏览次数</span>
                            <span style="display:inline-block" @click="createTime" :class="{'class1' : isActive == 3}">发布日期</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <p style="margin-top: 0;
    margin-bottom: 30px;" @click="backHome"> 返回首页</p>
                        </div>
                        <div v-for="item in dataList" :key="item.id" class="notice">
                            <img :src="item.matchLogo">
                            <div @click="toinfo(item)">
                                <h3>{{item.matchName}}</h3>
                                <p class="fuwen" v-html="item.matchIntroduce"></p>
                                <div class="zaxiang">
                                    <!-- <template v-if="item.genre.length>0 && item.genre[0] != ''">
                                      <el-tag v-for="item in item.genre" :key="item.id">{{item}}</el-tag>
                                    </template> -->

                                    <p>{{item.createTime}}</p>
                                    <!-- <p>{{item.views}} 浏览</p> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </el-main>
        <div class="footer">
            <div style="    margin-left: -147px;
    margin-top: 24px;">
                <p v-if='domain== "gansu"'>版权所有:甘肃省教育厅</p>
                <p v-if='domain== "gansu"'>技术支持:北京位来教育科技有限公司 CopyRight 2021</p>
                <p v-else>版权所有:北京位来教育科技有限公司 CopyRight 2021</p>
                <p style="
    text-align: left;
">备案号 :京ICP备20011457号-1</p>


                <!-- <p>商务合作：400-4333-222</p>
                <p>公司地址：北京市海淀区</p> -->
            </div>
            <div style="    margin-top: 10px;">
                <img src="../../assets/二维码.jpg" style="width:80px;height:80px">
                <p style="margin-top: 0;">官方公众号</p>
            </div>
        </div>
    </div>
</template>

<script>
    // import { Session } from '@/utils/storage';
    // import { getData } from "@/api/notice";
    export default {
        data() {
            return {
                params: {
                    page: 1,
                    limit: 0,
                    matchId: '',
                    orderRule: 'create_time'
                },
                dataList: {},
                isActive: 0,
                infoList: [],
                imgUrl: "https://wlzj-platform.posedu.com.cn",
                url3: '',
                url1: '',
                url2: '',
                domain: ''
            }
        },
        mounted() {
            this.domain = localStorage.getItem("domain");
            this.getList()
        },
        methods: {
            getList() {
                this.$http({
                    url: 'https://api.match.posedu.com.cn/match/notify/matchPageNotifyList',
                    method: 'post',
                    data: {
                        page: 1,
                        limit: 100,
                        domain: this.domain
                    }
                }).then(res => {
                    //  this.dataList = res.data.data.list
                    console.log('this.datalst', res);
                })

                this.$http({
                    url: 'https://api.match.posedu.com.cn/match/match/getDoorInfo',
                    //  url:'http://192.168.2.132:8080/match/match/getSchoolByDomain',

                    method: 'post',
                    data: {
                        code: this.domain

                    }
                }).then(res => {
                    if (res.data.data.doorInfo.name) {
                        let text = res.data.data.doorInfo.name
                        let replace = text.replace(/<[^>]+>/g, "");
                        document.title = replace
                    }

                    this.infoList = res.data.data
                    this.url1 = res.data.data.doorInfo.platform
                    this.url2 = res.data.data.doorInfo.aboutUs
                    this.dataList = res.data.data.matchList

                })


            },
            backHome() {
                this.$router.go(-1)
            },
            moren() {
                this.isActive = 1
                this.params.orderRule = 'sort'
                this.getList()
            },
            views() {
                this.isActive = 2
                console.log("点击views");
                this.params.orderRule = 'views'
                this.getList()
            },
            createTime() {
                this.isActive = 3
                this.params.orderRule = 'create_time'
                this.getList()
            },
            toinfo(e) {
                console.log("noticid", e)
                window.open(`https://${e.domain}.match.posedu.com.cn/`)
                // this.$router.push({path:'/matchInfo',query:{id:e}})
                // let routeUrl =this.$router.resolve({path:'/noticInfo',query:{id:e}})
                // window.open(routeUrl.href);
                // window.open(routeUrl.href, '_blank');

            },
            //锚点跳转
            goAnchor() {
                this.$router.push({path: "/", query: {mao: 1}})
                // var anchor = this.$el.querySelector(selector) // 参数为要跳转到的元素id,是(#id)
                // document.body.scrollTop = anchor.offsetTop; // chrome
                // document.documentElement.scrollTop = anchor.offsetTop; // firefox
            },
        }
    }
</script>

<style scoped>
    .allcontant {
        height: auto;
        min-height: calc(100vh - 140px);
        /* background-color: #f1f1f1; */
        margin-bottom: -30px;
        /* margin-top: 70px; */
    }

    .el-main {
        width: 65%;
        margin: 0 auto;
        min-height: 78vh
    }

    .newbox {
        display: flex;
        flex-wrap: wrap;
        background-color: #fff;

    }

    .zaxiang p:first-of-type {
        margin-left: 0;
        color: #ccc;
    }

    .el-tag {
        margin-right: 14px;
    }

    .newbox > div {
        width: 100%;
        min-height: 315px;
        /* background-color: #f5f5f5; */
        overflow: hidden;
    }

    .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #ccc;
    }

    .title p, .title h3 {
        margin: 15px;
    }

    .title span {
        padding: 0 5px;
    }

    .bread {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .notice {
        display: flex;
        align-items: center;
        border-bottom: 1px solid #ccc;
        padding-bottom: 15px;
    }

    .notice img {
        width: 184px;
        padding: 10px;
        height: 122px;
        min-width: 160px;
    }

    .fuwen {
        width: 49vw;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
        max-height: 70px;
        margin: 10px;
        margin-left: 0;
    }

    .zaxiang {
        display: flex;
        align-items: center;
    }

    .zaxiang p {
        margin: 0;
        margin-left: 10px;
    }

    .notice h3 {
        min-height: 28px;
        cursor: pointer !important;
        font-size: 19px;
        font-weight: 600;
        letter-spacing: 2px;
        margin: 0;
    }

    .notice h3:hover {
        color: #00baff;
    }

    .class1 {
        color: #00baff;
    }

    .title {
        display: none !important;
    }

    /* @media screen and (max-width:900px){
      .el-main {
        width: 100%;
        margin: 0 auto;
        padding: 0;
        margin-top: 31px;
    }
    .title {
        display: none !important;
    }
    .newbox .title{
      display: none;
    }
    span.el-tag.el-tag--light {
        display: none;
    }
    .zaxiang p:last-child {
        display: none;
    }
    .notice{
      border: none;
    }
    .el-main{
      padding: 0 20px 20px 20px;
    }
    .newbox>div{
      min-height: 0;
    }
    .notice h3 {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 200px !important;
    }
    p.fuwen {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
            width: 20rem;

    }
    .notice img {
        width: 12rem !important;
        height: 10rem;
            min-width: 12rem !important;
    }
    } */
    .hello {
        width: 75%;
        margin: 0 auto;
    }

    .head {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px !important;
        border-bottom: 1px solid #ccc;
        width: 72%;
        /* margin-left: -18%; */
        padding: 0 14%;
    }

    .headRight, .headLeft {
        display: flex;
        align-items: center;
    }

    .scrollimg {
        width: 99.5vw;
        margin-left: -18%;
    }

    .lexrsda {
        display: flex;
        flex-wrap: wrap;
    }

    .lexrsda a {
        width: 50%;
    }

    video {
        height: 81%;
        width: 100%;
    }

    /* .banner .slideBox .bd img {
        width: 100%;
        display: block;
    }
    .banner {
        width: 99vw;
        overflow: hidden;
    }
    .banner .slideBox {
        width: 100%;
        overflow: hidden;
        position: relative;
    }
    .banner .slideBox .bd {
        position: relative;
        height: 100%;
        z-index: 0;
    }
    .banner .slideBox .bd ul {
        width: 100%;
        margin: 0;
    }
    .banner .slideBox .bd li {
        zoom: 1;
        vertical-align: middle;
    } */
    .icon {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
        margin-bottom: 11px;
    }

    .matchhead {
        width: 100%;
        display: flex;
        justify-content: space-between;
        margin-bottom: 9px;
    }

    .matchnews {
        display: flex;
        flex-wrap: wrap;
        /* justify-content: space-between; */
        margin-top: 20px;
    }

    .info {
        border: 1px solid #ccc;
        margin-bottom: 10px;
        height: 254px;
    }

    .footer {
        background-color: #f5f5f5;
        /* width: 99.5vw;
        margin-left: -18%; */
        display: flex;
        justify-content: space-around;
    }

    .headRight > p {
        margin-right: 40px;
    }

    .schoolP {
        max-height: 600px;
        overflow-y: scroll;
        padding: 9px !important;
        display: flex;
        flex-wrap: wrap;
    }

    .contant {
        width: 19.3%;
        margin-bottom: 60px;
        cursor: pointer;
        margin: 35px;
        /* height: 500px; */
        /* padding: 0 37px; */
    }

    .contant .info p:hover {
        color: cornflowerblue
    }

    .contant img {
        width: 100%;
        height: 100%;
    }

    .img {
        display: flex;
    }

    .img img {
        /* width: 50px; */
        height: 47px;
        margin-right: 17px;
    }

    .info p {
        border: 1px solid #d4d0d0;
        border-top: 0;
        margin-top: -3px;
        height: 38px;
        padding-top: 11px;
    }

    .head {
        margin-bottom: 10px;
    }

    a {
        text-decoration: none;
        color: black;
    }

    .inputicon {
        width: 24px;
        height: 24px;
        /* background: url(../assets/搜索.png) no-repeat center center; */
        background-size: 100%;
        position: absolute;
        right: 10px;
    }

    .school a {
        color: #0e9ad9;
        padding: 5px 0px;
    }

    .lexrsda p {
        width: 50%;
    }

    div#slideBox {
        width: 100vw;
        margin-left: -18%;
    }

    #slideBox img {
        width: 100%;
    }

    .el-carousel.el-carousel--horizontal.el-carousel--card h2 {
        text-align: center;
        background-color: #fff;
        padding-top: 24px;
        margin-top: -16px;
        padding-bottom: 22px;
        margin-bottom: -10px;
        font-size: 22px;
    }

    .el-carousel__arrow, .el-carousel__arrow:hover {
        background-color: green;
        color: blue;
    }
</style>
